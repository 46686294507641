<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <div class="page-content pb-0">
      <div data-card-height="cover-full" class="card mb-0">
        <div class="card-center">
          <div class="text-center">
            <h1 class="font-40 color-highlight">PasJob</h1>
            <p class="font-600 opacity-80 color-theme mb-1 font-13">
              Lupa Password
            </p>
            <p class="boxed-text-xl color-theme opacity-50 pt-3 font-15">
              Masukkan email yang terhubung dengan akun anda kami akan
              mengirimkan instruksi reset password!
            </p>
          </div>

          <div class="content px-4">
            <div class="input-style no-borders has-icon validate-field mb-4">
              <i class="fa fa-at"></i>
              <input
                type="email"
                class="form-control validate-name"
                id="form1a"
                placeholder="Email"
                v-model="email"
              />
              <label for="form1a" class="color-highlight">Email</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em>(required)</em>
            </div>

            <a
              @click="submit"
              href="#"
              class="
                btn btn-full btn-l
                font-600 font-13
                gradient-highlight
                mt-4
                rounded-s
              "
              >Kirim</a
            >

            <div class="row pt-3 mb-3">
              <div class="col-6 text-start font-11">
                <router-link
                  :to="{ name: 'SignIn' }"
                  class="color-highlight"
                  @click="showUserType = true"
                >
                  Login</router-link
                >
              </div>
              <div class="col-6 text-end font-11">
                <router-link
                  :to="{ name: 'CreateConfirm' }"
                  class="color-highlight"
                  @click="showUserType = true"
                >
                  Buat Akun</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content ends here-->
    <!--Success Box-->
    <div
      id="success"
      class="menu menu-box-modal bg-highlight rounded-m"
      data-menu-height="330"
      data-menu-width="350"
    >
      <h1 class="text-center mt-4">
        <i
          class="
            fa fa-3x fa-check-circle
            scale-box
            color-white
            shadow-xl
            rounded-circle
          "
        ></i>
      </h1>
      <h1 class="text-center mt-3 font-700 color-white">Berhasil Dikirim</h1>
      <p class="boxed-text-l color-white opacity-90">
        Anda bisa buka email anda<br />
        Dan ikuti instruksi untuk mengganti kata sandi anda
      </p>
      <a
        @click="menuClose"
        class="
          btn btn-m btn-center-m
          button-s
          shadow-l
          rounded-s
          text-uppercase
          font-600
          bg-white
          color-black
          mb-1
        "
        >Tutup</a
      >
    </div>
    <!--End Success Box-->

    <!-- Failed Modals -->
    <div
      id="failed"
      class="menu menu-box-modal bg-red-dark rounded-m"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px; height: 310px"
    >
      <h1 class="text-center mt-4">
        <i
          class="
            fa fa-3x fa-times-circle
            scale-box
            color-white
            shadow-xl
            rounded-circle
          "
        ></i>
      </h1>
      <h1 class="text-center mt-3 text-uppercase color-white font-700">
        Wooops!
      </h1>
      <p class="boxed-text-l color-white opacity-70">
        Email anda tidak ditemukan<br />
        Silahkan mendaftar terlebih dahuu
      </p>
      <a
        @click="menuClose"
        href="#"
        class="
          close-menu
          btn btn-m btn-center-l
          button-s
          shadow-l
          rounded-s
          text-uppercase
          font-600
          bg-white
          color-black
        "
        >Keluar</a
      >
    </div>
    <!-- End Failed -->

    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import { init_template, menuOpen, menuClose } from "@/components/menu/index.js";

import Footer from "@/components/Footer.vue";
import axios from "axios";

export default {
  name: "ResetPassword",
  data() {
    return {
      email: "",
      isLoading: true,
      sending: false,
    };
  },
  components: { Footer },
  mounted() {
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    menuClose,
    menuOpen,
    async submit() {
      if (this.email !== "") {
        console.log(this.email);
        const data = {
          email: this.email,
        };
        await axios
          .post("/api/v1/auth/request-reset-email/", data)
          .then((response) => {
            if (response.data.success) {
              this.menuOpen("success");
              console.log(response.data.success);
            }
          })
          .catch((error) => {
            if (error.response.data.code === "Not Found") {
              this.menuOpen("failed");
            }
            if (error.response) {
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>
